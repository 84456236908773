.transaction_status {
  position: relative;
  font-size: 0;
  line-height: 13px;

  div.title {
    font-size: 14px;
  }

  span {
    background-color: #c4cbd1;
    float: none;
    display: inline-block;
    text-align: center;
    border-radius: 8px;
    width: 8px;
    height: 8px;
    float: none;
    margin-right: 4px;

    &:nth-child(5) {
      margin: 0;
    }
  }

  &.payment_status_pendingApproval {
  }

  &.payment_status_pending,
  &.payment_status_exchanging {
    span:nth-child(1) {
      background: #30c48d;
    }
  }

  &.payment_status_debiting {
    span:nth-child(1),
    span:nth-child(2) {
      background: #30c48d;
    }
  }

  &.payment_status_clearing {
    span:nth-child(1),
    span:nth-child(2),
    span:nth-child(3) {
      background: #30c48d;
    }
  }

  &.payment_status_completed {
    span {
      background-color: #30c48d;
    }
  }

  &.payment_status_unknown {
    span {
      background-color: #ffd215;
    }
  }

  &.payment_status_deleted,
  &.payment_status_rejected,
  &.payment_status_creditFailed {
    span {
      background-color: #ef5350;
    }
  }

  &.payment_status_sending {
    span:nth-child(1),
    span:nth-child(2),
    span:nth-child(3),
    span:nth-child(4) {
      background: #30c48d;
    }
  }
}

.request-status-awaiting {
  background-color: #c5cc4f;
  color: #fff;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
}
.request-status-validating_bank {
  background-color: #e19830;
  color: #fff;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
}
.request-status-processing {
  background-color: #c5cc4f;
  color: #fff;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
}
.request-status-completed {
  background-color: #30c48d;
  color: #fff;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
}
.request-status-pad {
  background-color: #fff;
  color: #000;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  font-size: 11px;
  text-transform: uppercase;
  border-color: #000;
  border: 1px solid #ccc;
  white-space: nowrap;
  display: inline-block;
}

.bank_status_depositPending {
  background-color: #c5cc4f;
  color: #fff;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
}
.bank_status_attention {
  background-color: #e19830;
  color: #fff;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
}
.bank_status_locked {
  background-color: #f16457;
  color: #fff;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
}
.bank_status_verified {
  background-color: #30c48d;
  color: #fff;
  padding: 4px 8px 4px 8px;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
}
