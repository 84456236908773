.reconciliation-status {
  display: block;
  line-height: 28px;
  float: right;
  margin-right: 40px;
  color: #3f484d;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.reconciliation-status-label {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-size: 13px !important;
  text-transform: uppercase;
  color: #fff;

  &.reconciliation-status-label-UpdateRequired {
    background-color: #e19830;
  }
  &.reconciliation-status-label-Reconciled {
    background-color: #30c48d;
  }
}
.plooto-clearing {
  font-style: normal;
  text-decoration: underline;
}
