#dashboardChart {
  #canvas {
    width: 100%;
    height: 400px;
  }
  div.placeholder {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    font-size: 12px;
    font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;
    color: rgb(96, 96, 106);
    font-weight: bold;

    p {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
}

.dashboardTutorial {
  background-color: #f5f6f8;
  margin: 0;
  padding: 0;
  margin-top: -80px;
  margin-bottom: 20px;

  h2 {
    padding-top: 40px;
    font-size: 32px;
    text-align: center;
    margin-bottom: 45px;
    color: #5e676b;
  }
  .step {
    width: 50%;
    float: left;
    padding-left: 130px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 15px;
    background: no-repeat
      url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogPGc+DQogIDx0aXRsZT5MYXllciAxPC90aXRsZT4NCiAgPGNpcmNsZSBzdHJva2U9IiNlM2U4ZWMiIGZpbGwtb3BhY2l0eT0iMC4wMSIgZmlsbD0iI2RhZGVlMCIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtZGFzaGFycmF5PSJudWxsIiBzdHJva2UtbGluZWpvaW49Im51bGwiIHN0cm9rZS1saW5lY2FwPSJudWxsIiBjeD0iMzAiIGN5PSIzMCIgcj0iMjgiIGlkPSJzdmdfMSIvPg0KICA8cGF0aCBmaWxsPSIjZTNlOGVjIiBkPSJtMzMuODY4NywyOS45OTk3M2w2LjYyMjQzLC02LjYyMjQyYzEuMDQ3OTQsLTEuMDQ3OTMgMS4wNDc5NCwtMi43NDY3MyAwLC0zLjc5NDEzYy0xLjA0Njg2LC0xLjA0NzQgLTIuNzQ2NzMsLTEuMDQ3NCAtMy43OTM1OSwwbC02LjYyMjk2LDYuNjIyOTZsLTYuNjIyOTYsLTYuNjIyOTZjLTEuMDQ2ODYsLTEuMDQ3NCAtMi43NDY3MywtMS4wNDc0IC0zLjc5MzU5LDBjLTEuMDQ3OTMsMS4wNDc5MyAtMS4wNDc5MywyLjc0NjczIDAsMy43OTQxM2w2LjYyMjQzLDYuNjIyNDJsLTYuNjIyNDMsNi42MjI0M2MtMS4wNDc5MywxLjA0NzkzIC0xLjA0NzkzLDIuNzQ2NzMgMCwzLjc5NDEzYzAuNTIzNywwLjUyMzcgMS4yMTA1MiwwLjc4NTU1IDEuODk2OCwwLjc4NTU1czEuMzczNjQsLTAuMjYxODUgMS44OTY4LC0wLjc4NTU1bDYuNjIyOTYsLTYuNjIyNDNsNi42MjI5Niw2LjYyMjk2YzAuNTIzNjksMC41MjM3IDEuMjEwNTEsMC43ODU1NSAxLjg5Njc5LDAuNzg1NTVzMS4zNzM2NCwtMC4yNjE4NSAxLjg5NjgsLTAuNzg1NTVjMS4wNDc5NCwtMS4wNDc5NCAxLjA0Nzk0LC0yLjc0Njc0IDAsLTMuNzk0MTNsLTYuNjIyNDMsLTYuNjIyOTZsLTAuMDAwMDEsMHoiIGlkPSJzdmdfMiIvPg0KIDwvZz4NCjwvc3ZnPg==)
      50px 0px;

    div.area {
      display: inline-block;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    &.completed {
      background: no-repeat
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogPGc+DQogIDx0aXRsZT5MYXllciAxPC90aXRsZT4NCiAgPGNpcmNsZSBzdHJva2U9IiM4NGU1YzIiIGZpbGwtb3BhY2l0eT0iMC4wMSIgZmlsbD0iI2RhZGVlMCIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtZGFzaGFycmF5PSJudWxsIiBzdHJva2UtbGluZWpvaW49Im51bGwiIHN0cm9rZS1saW5lY2FwPSJudWxsIiBjeD0iMzAiIGN5PSIzMCIgcj0iMjgiIGlkPSJzdmdfMSIvPg0KICA8cGF0aCBzdHJva2U9IiM1NmZmNTYiIHN0cm9rZS13aWR0aD0iMCIgZmlsbD0iIzg0ZTVjMiIgaWQ9InN2Z18zIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0ibTQyLjQ1NjM5LDE5LjMxNzIxYy0xLjM2MDE4LC0xLjM4NzQ2IC0zLjU2NDYxLC0xLjM4NzQ2IC00LjkyNDc4LDBsLTEyLjMxMjM4LDEyLjU1Nzk4bC0zLjA3ODUyLC0zLjEzOTkyYy0xLjM2MDE4LC0xLjM4NzQ2IC0zLjU2NDYxLC0xLjM4NzQ2IC00LjkyNDc4LDBjLTEuMzYwMTgsMS4zODc0NiAtMS4zNjAxOCwzLjYzNjIzIDAsNS4wMjI4NWw1LjU0MDQ5LDUuNjUxMzVjMS4zNjAxOCwxLjM4NzQ2IDMuNTY0NiwxLjM4NzQ2IDQuOTI0NzgsMGwxNC43NzUyLC0xNS4wNjg1NmMxLjM2MDE4LC0xLjM4NzQ3IDEuMzYwMTgsLTMuNjM2MjQgMCwtNS4wMjM3eiIvPg0KIDwvZz4NCjwvc3ZnPg==)
        50px 0px;
    }
    h4 {
      text-align: left;
      margin: 0;
      color: #606e75;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 16px;
    }
    p {
      font-size: 16px;
      color: #3f484d;
      padding-bottom: 0;
      margin: 0;
    }
  }
}

.outstanding_bills,
.outstanding_invoices {
  margin-bottom: 0;
  .chooseAccountingSoftware {
    height: 100px;
    text-align: center;
  }
}

.pagination_page {
  border: none;
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #2ca6ca;
  color: white;
  &:hover {
    text-decoration: none;
    color: white;
  }
  &.active {
    background-color: #cccccc;
  }
}

.sort-column {
  cursor: pointer;
}

.sort-column span::after {
  display: none;
  color: transparent;
  padding-left: 6px;
  font: normal normal normal 16px/1 FontAwesome;
  content: '\f106';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sort-column:hover {
  background-color: #fcfdfd;
}

.sort-column.active {
}

.sort-column.active span::after {
  display: inline-block;
  color: #3f484d;
  content: '\f107';
}

.sort-column.active.asc span::after {
  color: #3f484d;
  content: '\f106';
}

.recent_payments thead th {
  vertical-align: middle !important;
  padding: 7px 15px !important;
}

.integration-outstanding-items,
.dashboard-transactions {
  margin-bottom: 20px;
  .tab-content {
    border: 1px solid #ddd;
    border-top: 0;
  }
  .nav-tabs > li > a {
    background: #eee;
    color: #333;
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    background: #f2f5f6;
    font-weight: 600;
  }

  table {
    margin-bottom: 0;
  }
}
.company_demo_indicator {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 1030;
}

.company_demo_indicator div {
  margin-left: -50%;
  margin-right: 50%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background-color: #fff7e5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.tutorial-visible .company_demo_indicator {
  display: none;
}
