.account-requires-activation {
  border: 1px solid #cbd9df;
  padding: 18px;
  margin-bottom: 25px;
  display: none;
  height: 180px;

  &.largeActivation {
    height: 250px;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .fa {
    font-size: 28px;
    color: #fdc74d;
    vertical-align: middle;
    padding-right: 5px;
  }
  .col-code {
    padding-right: 5px;
  }
  .col-activate {
    padding-left: 5px;
  }
  input[type='text'],
  select {
    font-size: 16px;
  }
  .account-actions {
    text-align: center;
    color: #dfe7eb;
    font-size: 16px;
    margin-top: 25px;
    a {
      color: #2ca6ca;
    }
  }
}
.modal-plooto-bank-activated {
  width: 500px;
  .plooto-bank-activated {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGEAAABhCAMAAADx2D+RAAAAflBMVEX5+fr///8Aw5zu7+/w8PDz8/Px8vL19vb9/f339/f4+Pn09fX7+/v3+PkHxZ8gyqkLxqDy+PjK7+lJ07jq9vXD7uZj2cE/0bQRx6Pb8+9+38wVyKTX8u2z6uA0z7Dk9fK87OOa5deh59lb175T1bwuza7e8/Cs6N1t28aR4tPu51xQAAAEIUlEQVRo3q2a23abMBBFB0ZXJLmOHcdJk7Zpm/Ty/z9Ygy8nXQIkhM5DHsgy25qZM8IjqMmT8Z11Ug2STndkmkzlEHxwgiMJGXwVgj/fXSgptbaDtHZSna86WkkwVgzfVncUqdNy+Kf25QTvmFlJS5PqnGJm6csIXvb315SQ7VciaTnBuP7LBcrRwPALCaH/UEe56qtBmwUEo5hVR0skmYXPJpBgoWmhrGK2KQIyIAlasgxpZgmIkKMiacHCJwlesLBUqE6xoATBC6S4MFI0QQCAoCoIqgcAYpJgAFiFEH6CYBQAKxFmnOBYdFRDitUogZgtVVEnWH8kIMuOKskyU0xQaBU1UwGCZUEVpdiBcC1UTVCNVND/BMmSqsqx+o/gUaj14tSBgCVUlGZhQPBIc81FBBCwhLqLuBEM85osvLw9b/e7sUXQlaBZrgE8tydtY4RjeSWINQ3p16d20JEiMZszgdY07W8XQLsZ6x32THArWt79e3vR41iu1ZkgOJQC7o7tVQ8US/RhojVmuNvfAJ++TfmaGltcSeHtBtjsxi0he4Is7qoP7U3fxzssi54gSu32p51JAhJBpjQNnwF4v8PlyNZU6oa/ADw+ERQ5gkJZon+00JeZ7uca0kV++91CnwkaKSYqKqXXDQD7Gb8GFmWEw7aNrDZZrqQSjTVQpN1jO281iLmheTscju1m/xJtCLHVpg0xTzhshmrcRRsCrLaScLwU/OHDhvDeQl/v1hKuJbN9vW0IX9vIagnCbKafWyCwISStBjEIqea2/YINAVZLKumHe2R1c0KEnx8Be4Lm/OBmHffaAvF72BBgtfucnyqqOf2RmT16gxAlrYa+lOzeAbUDwWoJOdYNpR4EnrYtlGG1eAdqmHN3gnyrwQ7+REi1PvoZA54jq809CWALSpRsptXip5n0Rn1oIVgtQ5JDTzBIRLJkYbXcNPSEjEeycFxkNfhNNAOhSz/PPD0CsN3lTwZcT8j7kfVjidXgBn8mNBLVlC7Zh2U/FQcCqilRsrBa5hLsldAo5DqxZcNqOXk2FwJyPY84brZvL0RL8oypQ83JDKZYBoRoEbUmTCA0CuVUdXACAtUe/wgODQi9ZN04SQxECfNiVzXN/iOhfpwE65GJrkOcqg6NQTCKZdVpKwiYGtdB6MvEGAQgmGWVLHeTJxzErCsA9MwpTcAqygGuiQgVERqACULTrUJIhAiE6MKK8zIcXE4QcOani1KA875RAmQksyyKkPK5p8dh+TK0mjqhpskTfKWXHR0Lv+wUvxMnhs28v2QW1jT5BLxIoXRefNiZkrcpjD4xhLSJNx1w/3wCGEHhhZCx2ytGfJYRIK8FDxSn7Y3TWe3k+brzFd7M8UMoBolBfJHSuH0pAZROSyX4BpKu86be20WQGdQs0j8bai0vjnl1IQAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: top center;
    text-align: center;
    h4 {
      font-size: 36px;
      color: #30c48d;
      padding-top: 110px;
      font-weight: 300;
      padding-bottom: 30px;
    }
    p {
      line-height: 26px;
      font-size: 16px;
    }
  }
  .modal-footer {
    padding-bottom: 50px;
  }
}
