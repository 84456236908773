.widget-guided-tour {
  display: none;
  .hole-part {
    background: rgba(51, 51, 51, 0.9);
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1050;

    &.hole-inside {
      background: transparent;
      border: 2px solid #fff;
      border-radius: 3px;
    }

    &.hole-content {
      background: transparent url('../../../img/help/guided-dashed.png');
      background-repeat: no-repeat;
      background-position: top center;
      height: 300px;
      padding-top: 100px;
      width: 460px;

      h4 {
        color: #02eba9;
        font-size: 19px;
        margin-bottom: 5px;
      }

      p {
        color: #fff;
        font-size: 14px;
      }
      &.top-description {
        padding-top: 0;
        padding-bottom: 100px;
        background-position: bottom center;
        height: auto;
      }
    }
  }

  .guide-arrows {
    display: inline-block;
    margin-top: 24px;
    a {
      display: inline-block;
      float: left;
      background: #ecfbfa;
      color: #9fd6bf;
      padding: 8px 25px;
      &:hover,
      &:focus {
        text-decoration: none;
      }
      &.arrow-left {
        -webkit-border-top-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        line-height: 34px;
        height: 41px;
        font-size: 24px;
        vertical-align: middle;
        padding: 0 15px;
      }
      &.arrow-right {
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background: #30c48d;
        color: #fff;
        &.standalone {
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }

        &:hover {
          text-decoration: none;
          background: #0e9a77;
        }
      }
    }
  }
  .guide-description {
    width: 460px;
    text-align: center;
    margin: 0 auto;
  }
}
.getting-started-visible {
  .widget-guided-tour {
    display: block;
    background: rgba(51, 51, 51, 0.9);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1050;
    .hole-part {
      visibility: hidden;
    }
  }
}
