.navbar-nav > li > a {
}
.btn.btn-form {
  margin-top: 10px;
}

button.icon {
  border: 0;
  background-color: transparent;
}
button.icon:focus {
  border: 0;
  outline: 0;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #fff;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
}

.btn-success {
  background-color: #84e5c2;
  font-weight: normal;
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-color: #84e5c2;
}

.btn.busy span:first-of-type {
  visibility: hidden;
}
@-webkit-keyframes busy-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes busy-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.btn-export {
  background: #fafafa;
  color: #868a8d;
  font-weight: 600;
  border-color: #c2c7cc;
  border-width: 1px;
  border: 1px solid #c2c7cc;
  &:hover,
  &:active,
  &:focus {
    background: #fff;
    color: #868a8d;
  }
}

.icon_connect {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PHRpdGxlPkxheWVyIDE8L3RpdGxlPjxwYXRoIGQ9Im0yOS43IDIuNGwtMC4xLTAuMWMtMi45LTIuOS03LjctMi45LTEwLjYgMGwtNi44IDYuOGMtMi45IDIuOS0yLjkgNy43IDAgMTAuNmwwLjEgMC4xYzAuMiAwLjIgMC41IDAuNSAwLjggMC43bDIuNS0yLjVjLTAuMy0wLjItMC42LTAuNC0wLjgtMC42bC0wLjEtMC4xYy0xLjYtMS42LTEuNi00LjIgMC01LjhsNi44LTYuOGMxLjYtMS42IDQuMi0xLjYgNS44IDBsMC4xIDAuMWMxLjYgMS42IDEuNiA0LjIgMCA1LjhsLTMuMSAzLjFjMC41IDEuMyAwLjggMi43IDAuOCA0LjFsNC43LTQuN2MyLjktMi45IDIuOS03LjcgMC0xMC42bDAgMHptLTEwIDkuOGMtMC4yLTAuMi0wLjUtMC41LTAuOC0wLjdsLTIuNSAyLjVjMC4zIDAuMiAwLjYgMC40IDAuOCAwLjZsMC4xIDAuMWMxLjYgMS42IDEuNiA0LjIgMCA1LjhsLTYuOCA2LjhjLTEuNiAxLjYtNC4yIDEuNi01LjggMGwtMC4xLTAuMWMtMS42LTEuNi0xLjYtNC4yIDAtNS44bDMuMS0zLjFjLTAuNS0xLjMtMC44LTIuNy0wLjgtNC4xbC00LjcgNC43Yy0yLjkgMi45LTIuOSA3LjcgMCAxMC42bDAuMSAwLjFjMi45IDIuOSA3LjcgMi45IDEwLjYgMGw2LjgtNi44YzIuOS0yLjkgMi45LTcuNyAwLTEwLjZsLTAuMS0wLjF6IiBpZD0ic3ZnXzEiIGZpbGw9IiM5OGIxYmMiLz48L3N2Zz4=') !important;
  background-position: top center;
  background-repeat: no-repeat;
}
.icon_pdf_white {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PGc+PGc+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0ibTkuMyAxNi40YzAtMC45LTAuNi0xLjQtMS43LTEuNCAtMC40IDAtMC43IDAtMC45IDAuMWwwIDIuOGMwLjIgMCAwLjQgMC4xIDAuNyAwLjEgMS4xIDAgMS44LTAuNiAxLjgtMS41eiIgaWQ9InN2Z18yIi8+PHBhdGggZmlsbD0iI2ZmZmZmZiIgc3Ryb2tlPSJudWxsIiBkPSJtMTUuOSAxNWMtMC41IDAtMC44IDAtMSAwLjFsMCA2LjFjMC4yIDAgMC41IDAgMC43IDAgMS45IDAgMy4yLTEgMy4yLTMuMyAwLTEuOS0xLjEtMy0yLjktM3oiIGlkPSJzdmdfMyIvPjxwYXRoIGZpbGw9IiNmZmZmZmYiIHN0cm9rZT0ibnVsbCIgZD0ibTI4LjQgMTEuNWwtMC44IDAgMC0zLjdjMCAwIDAgMCAwLTAuMSAwLTAuMSAwLTAuMy0wLjEtMC40bC02LjItN2MwIDAgMCAwIDAgMCAwIDAtMC4xLTAuMS0wLjEtMC4xIDAgMCAwIDAgMCAwIDAgMC0wLjEgMC0wLjEtMC4xIDAgMCAwIDAgMCAwIDAgMC0wLjEgMC0wLjEgMGwtMTUuMSAwYy0wLjcgMC0xLjMgMC42LTEuMyAxLjNsMCAxMC4yIC0wLjggMGMtMSAwLTEuOCAwLjgtMS44IDEuOGwwIDkuM2MwIDEgMC44IDEuOCAxLjggMS44bDAuOCAwIDAgNi40YzAgMC43IDAuNiAxLjMgMS4zIDEuM2wyMC43IDBjMC43IDAgMS4zLTAuNiAxLjMtMS4zbDAtNi40IDAuOCAwYzEgMCAxLjgtMC44IDEuOC0xLjhsMC05LjNjMC0xLTAuOC0xLjgtMS44LTEuOHptLTIyLjctMTAuMmwxNC41IDAgMCA2LjRjMCAwLjMgMC4zIDAuNiAwLjYgMC42bDUuNSAwIDAgMy4xIC0yMC43IDAgMC0xMC4yIDAgMHptMTUuNCAxNi43YzAgMS44LTAuNyAzLTEuNiAzLjggLTEgMC44LTIuNSAxLjItNC4zIDEuMiAtMS4xIDAtMS45LTAuMS0yLjQtMC4xbDAtOS4yYzAuOC0wLjEgMS44LTAuMiAyLjktMC4yIDEuOCAwIDIuOSAwLjMgMy44IDEgMSAwLjcgMS42IDEuOSAxLjYgMy41bDAgMHptLTE2LjMgNC45bDAtOS4yYzAuNy0wLjEgMS42LTAuMiAyLjktMC4yIDEuMyAwIDIuMiAwLjIgMi45IDAuNyAwLjYgMC41IDEgMS4yIDEgMi4yIDAgMC45LTAuMyAxLjctMC45IDIuMiAtMC43IDAuNy0xLjggMS0zIDEgLTAuMyAwLTAuNSAwLTAuNyAwbDAgMy4zIC0yLjEgMCAwIDAgMCAwem0yMS42IDcuNWwtMjAuNyAwIDAtNiAyMC43IDAgMCA2IDAgMHptMS44LTE1LjJsLTMuNiAwIDAgMi4xIDMuNCAwIDAgMS43IC0zLjQgMCAwIDMuOCAtMi4xIDAgMC05LjQgNS43IDAgMCAxLjd6IiBpZD0ic3ZnXzQiLz48L2c+PC9nPjwvc3ZnPg==') !important;
  background-position: top center;
  background-repeat: no-repeat;
}

.icon_undo_white {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0ibTE2LjUgMWMtNC43IDAtOS4yIDIuMi0xMi4xIDUuOWwtNC40LTIuNiAwIDE0LjYgMTIuNy03LjMgLTQuMS0yLjRjMi0yLjIgNC45LTMuNiA3LjktMy42IDUuOSAwIDEwLjYgNC44IDEwLjYgMTAuNiAwIDUuOS00LjggMTAuNi0xMC42IDEwLjZsMCA0LjdjOC41IDAgMTUuNC02LjkgMTUuNC0xNS40IDAtOC41LTYuOS0xNS40LTE1LjQtMTUuNHoiLz48L3N2Zz4=') !important;
  background-position: top center;
  background-repeat: no-repeat;
}
.icon_add_white {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0ibTI2LjQ1IDEzLjIxbC03LjY2IDAgMC03LjY2YzAtMC4zOC0wLjMxLTAuNy0wLjctMC43bC00LjE4IDBjLTAuMzggMC0wLjcgMC4zMS0wLjcgMC43bDAgNy42NiAtNy42NiAwYy0wLjM4IDAtMC43IDAuMzEtMC43IDAuN2wwIDQuMThjMCAwLjM4IDAuMzEgMC43IDAuNyAwLjdsNy42NiAwIDAgNy42NmMwIDAuMzggMC4zMSAwLjcgMC43IDAuN2w0LjE4IDBjMC4zOCAwIDAuNy0wLjMxIDAuNy0wLjdsMC03LjY2IDcuNjYgMGMwLjM4IDAgMC43LTAuMzEgMC43LTAuN2wwLTQuMThjMC0wLjM4LTAuMzEtMC43LTAuNy0wLjd6Ii8+PC9zdmc+') !important;
  background-position: top center;
  background-repeat: no-repeat;
}
.icon_add {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiI+PHBhdGggZmlsbD0iIzk4YjFiYyIgZD0ibTI2LjQ1IDEzLjIxbC03LjY2IDAgMC03LjY2YzAtMC4zOC0wLjMxLTAuNy0wLjctMC43bC00LjE4IDBjLTAuMzggMC0wLjcgMC4zMS0wLjcgMC43bDAgNy42NiAtNy42NiAwYy0wLjM4IDAtMC43IDAuMzEtMC43IDAuN2wwIDQuMThjMCAwLjM4IDAuMzEgMC43IDAuNyAwLjdsNy42NiAwIDAgNy42NmMwIDAuMzggMC4zMSAwLjcgMC43IDAuN2w0LjE4IDBjMC4zOCAwIDAuNy0wLjMxIDAuNy0wLjdsMC03LjY2IDcuNjYgMGMwLjM4IDAgMC43LTAuMzEgMC43LTAuN2wwLTQuMThjMC0wLjM4LTAuMzEtMC43LTAuNy0wLjd6Ii8+PC9zdmc+') !important;
  background-position: top center;
  background-repeat: no-repeat;
}

.icon.disabled {
  cursor: default !important;
  opacity: 0.6;
  pointer-events: none;
}
.btn.icon {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  margin-top: -10px;
  margin-left: 15px;
}

.btn.icon:active,
.btn.icon:focus,
.btn.icon.active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn.icon.btn-danger {
  background-color: #fa8d83;
  background-position: center center;
}
.btn.icon.btn-default {
  background-color: #cdd8de;
  background-position: center center;
}
.btn.icon.btn-success {
  background-color: #2ca6ca;
  background-position: center center;
}

table.table .btn-sm.icon {
  margin: 0;
  padding: 0;
  border-radius: 0;
  width: 32px;
  height: 32px;
  display: inline-block;
  opacity: 0.6;
}

.btn-group .dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.dropdown-menu.payee_import {
  background-color: #2ca6ca;
  margin-top: 0px;
  padding: 0;
  border: 0;
  min-width: 200px;

  margin-right: 17px;
}

.dropdown-menu.payee_import li a {
  padding: 17px 10px;
  margin: 0;
  color: #fff;
  text-shadow: none;
  font-size: 20px;
  text-align: center;
  height: auto;
  border-radius: 5px;
}

.third_party_quickbooks_signin {
  outline: none;
  text-decoration: none;
  border: none;
  background: url(../img/3rd_party/logos/signin_to_quickbooks.png) no-repeat 0 0;
  width: 161px;
  height: 36px;
  display: inline-block;
  text-indent: -9000px;
  text-transform: capitalize;
}
.third_party_quickbooks_signin:hover {
  background-position-y: -36px;
}

.third_party_quickbooks_connect {
  outline: none;
  text-decoration: none;
  border: none;
  background: url(../img/3rd_party/logos/connect_to_quickbooks.png) no-repeat 0 0;
  width: 163px;
  height: 24px;
  display: inline-block;
  text-indent: -9000px;
  text-transform: capitalize;
  margin-bottom: 7px;
  margin-right: 5px;
  &:hover {
    background-position: 0 -24px;
  }
}

.third_party_quickbooks_online_connect2 {
  outline: none;
  text-decoration: none;
  border: none;
  background: url(../img/3rd_party/logos/connect_to_quickbooks2.png) no-repeat 0 0;
  width: 162px;
  height: 28px;
  display: inline-block;
  text-indent: -9000px;
  text-transform: capitalize;
  margin-bottom: 7px;
  margin-right: 5px;

  &:hover {
    background-position: 0 -28px;
  }
}

.third_party_quickbooks_desktop_connect {
  outline: none;
  text-decoration: none;
  border: none;
  background: url(../img/3rd_party/logos/connect_to_quickbooks_windows.png) no-repeat 0 0;
  width: 162px;
  height: 28px;
  display: inline-block;
  text-indent: -9000px;
  text-transform: capitalize;
  margin-bottom: 7px;
  margin-right: 5px;

  &:hover {
    background-position: 0 -28px;
  }
}

.third_party_quickbooks_desktop_connect2 {
  outline: none;
  text-decoration: none;
  border: none;
  background: url(../img/3rd_party/logos/connect_to_quickbooks_windows2.png) no-repeat 0 0;
  width: 162px;
  height: 28px;
  display: inline-block;
  text-indent: -9000px;
  text-transform: capitalize;
  margin-bottom: 7px;
  margin-right: 5px;
  &:hover {
    background-position: 0 -28px;
  }
}

.third_party_xero_connect {
  outline: none;
  text-decoration: none;
  border: none;
  background: url(../img/3rd_party/logos/connect_xero_button_blue.png) no-repeat 0 0;
  width: 168px;
  height: 28px;
  display: inline-block;
  text-indent: -9000px;
  text-transform: capitalize;
  margin-bottom: 7px;
  &:hover {
    background-position: 0 -28px;
  }
}

.third_party_netsuite_connect {
  outline: none;
  text-decoration: none;
  border: none;
  background: url(../img/3rd_party/logos/connect_xero_button_blue.png) no-repeat 0 0;
  width: 168px;
  height: 28px;
  display: inline-block;
  text-indent: -9000px;
  text-transform: capitalize;
  margin-bottom: 7px;
  &:hover {
    background-position: 0 -28px;
  }
}

.third_party_xero_connect2 {
  outline: none;
  text-decoration: none;
  border: none;
  background: url(../img/3rd_party/logos/connect_xero_button_blue2.png) no-repeat 0 0;
  width: 161px;
  height: 28px;
  display: inline-block;
  text-indent: -9000px;
  text-transform: capitalize;
  margin-bottom: 7px;

  &:hover {
    background-position: 0 -28px;
  }
}
