.ssoSignInButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.intuitSignInButton {
  width: 161px;
  height: 36px;
  background-image: url(/Content/img/accountingSoftware/intuit-signin-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  background-color: unset;
}

.intuitSignInButton:hover,
.intuitSignInButton:focus,
.intuitSignInButton:active {
  background-image: url(/Content/img/accountingSoftware/intuit-signin-button-hover.svg);
}

.authenticate_or {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.authenticate_or span.line {
  background: #000;
  border-top: solid 1px #cbd9df;
  position: absolute;
  height: 1px;
  display: block;
  top: 50%;
  width: 100%;
}

.authenticate_or span.text {
  background-color: #fff;
  z-index: 20;
  position: relative;
  text-align: center;
  padding: 0 34px;
  font-size: 14px;
}

.form-small-progress {
  display: none;
}
.form-submitting .form-small-progress {
  display: inline-block;
}
.forgot-password-row {
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: left;
  font-size: 17px;
  margin-top: 20px;
}
