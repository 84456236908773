.component-recurring-plan-details {
  background: #f2fff1;
  border: 1px solid #e2efe1;
  padding: 15px;
  line-height: 27px;
  border-radius: 5px;

  strong {
    &.payment-heading {
      display: block;
      padding-bottom: 14px;
    }
  }
}
.paymentRequestInvoiceView .component-recurring-plan-details {
  margin-top: 20px;
  margin-bottom: 20px;
}
