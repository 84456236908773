@import 'variables.less';

.settings_pane {
  .pending-user {
    color: #ffa500;
  }
  .pending-mode-user {
    background: #fff4ea;
    border-color: #900;
    margin: 10px 0px 30px 0;
  }
}
