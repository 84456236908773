.widget-first-time-payment {
  text-align: center;
  h1 {
    font-size: 29px;
    margin-bottom: 30px;
  }
  h2 {
    color: #868686;
    font-size: 20px;
  }
  .payment-methods {
    background: #f7fbfa;
    padding: 30px;
  }
  .button-container {
    padding: 25px;
    margin-top: 35px;
    background: #f7fbfa;
    display: inline-block;

    .img-button {
      margin-left: 25px;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
}
