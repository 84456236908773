.signin-page-container {
  .row-left {
    padding-right: 50px;
  }

  .row-right {
    border-left: 1px solid #eee;
    padding-left: 50px;
  }
}

.payment-request-client-view {
  padding-top: 20px;
  .form-logic-group-payment {
    padding: 0;
    h3 {
      padding-bottom: 30px;
    }
  }
}

.payor-dropdown {
  display: inline-block;
  .btn-export {
    background: #fff !important;
    color: #868a8d !important;
  }
  .dropdown-menu {
    width: 300px;
    padding: 10px 20px;
    i {
      font-size: 20px;
    }
    label {
      font-weight: bold;
      padding-bottom: 5px;
    }
    input {
      font-size: 14px;
      resize: none;
      border: 1px solid #cccccc;
    }
  }
}

.refund-dropdown {
  display: inline-block;

  textarea {
    resize: none;
  }

  .btn-export {
    background: #fff !important;
    color: #868a8d !important;
  }

  .dropdown-menu {
    width: calc(3vw + 330px);
    padding: 10px 20px;

    i {
      font-size: 20px;
    }

    label {
      font-weight: bold;
      padding-bottom: 5px;
    }

    input {
      font-size: 14px;
      resize: none;
      border: 1px solid #cccccc;
    }
  }
}

.payment-link {
  font-size: 15px;
  vertical-align: middle;
}
.company-name-icon {
  color: #d99a03;
}
.error-label .company-name-icon {
  display: none;
}
.payment-request-for-payee {
  background: #ffdc9f;
  color: #000;
  padding: 15px;
  text-align: center;
  font-size: 22px;
}

tr {
  .fa {
    &.disabled {
      color: #ccc;
    }
  }
}

.forced-date {
  height: 42px;
  display: block;
  line-height: 42px;
  text-align: left;
  color: #aaa;
}

.way-to-pay {
  margin: 50px 20px 20px 40px;
  font-weight: 600;
}
.form-sub-option {
  margin-top: 7px;
  padding-left: 20px;
  .icon-sub {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAM1BMVEX////MzMzq6urg4ODa2trt7e37+/vPz8/5+fnd3d3v7+/i4uLZ2dnW1tbS0tLm5ubz8/OxN+DfAAAAcklEQVQ4y+3RQQ7DIAxE0RkIBkJoev/T1qqlio3NvsrbWfoLsMGvBM8T/HtQxi7gbJuANcWButomYC02VZuOxaTpAtXpe50anIwMAY6w6IBM+m4oye4b3jAtjby4aLI45ym/L0RBF/iBrTEK9BBhcC/DB8hyA18ikGfMAAAAAElFTkSuQmCC');
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }
  .icon-sub-long {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAA4BAMAAABu0ecxAAAAKlBMVEX////MzMzq6urh4eHa2trt7e37+/v5+fnd3d3v7+/W1tbS0tLm5ubz8/NM8WqgAAAAdElEQVQoz2MQFBRUYEACowKjAoNJgNUAXUCqAE1AUAhdQNAdXUAwACIg4gICXkCWRAIDQ6MgMtjJwDBREAWYMbCgCkgwsC1EETjAwJCMzN8Lsq/IGAxMgHzhBIjzYe6QnsCAKtDGgCogyoAqIFKAJnAATAEAtV0hM/dLW38AAAAASUVORK5CYII=');
    display: inline-block;
    width: 32px;
    height: 56px;
    margin-right: 5px;
  }

  input {
    display: inline-block;
    margin-right: 10px;
  }
  .flexselect-container {
    display: inline-block;
  }
}
.status-cancelled {
  color: #900;
  font-weight: 600;
  background: #ffbdbd;
  padding: 5px;
}
.status-pending {
  color: #000;
  font-weight: 600;
  background: #ffd25a;
  padding: 5px;
}
.requestOverview {
  display: none;
  background-color: #f9f9fa;
  padding: 20px;
  border-radius: 5px;
  line-height: 36px;
  font-size: 21px;
}

.requestOverview a {
  color: #30c48d;
  font-weight: bold;
  text-decoration: underline;
}
.h3-35px {
  line-height: 35px;
  height: 35px;
}

.check-sample-wrapper {
  margin-top: 25px;
}
.request-from-name {
  line-height: 38px;
  padding-left: 5px;
}
.bank-select-radio-select-group {
  .requires-details {
    margin-right: 8px;
    color: #ffa200;
    font-size: 22px;
    text-indent: 0;
  }
  .valid-details {
    margin-right: 8px;
    color: #30c48d;
    font-size: 22px;
  }

  div {
    clear: both;
    overflow: hidden;
  }

  label {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #d1d3d4;
    font-weight: normal;
  }

  input[type='radio'],
  input[type='checkbox'] {
    &:empty {
      display: none;

      ~ label {
        position: relative;
        line-height: 36px;
        padding-left: 43px;
        margin-bottom: 15px;
        cursor: pointer;
        user-select: none;
        font-size: 14px;
        height: 40px;
        overflow: hidden;

        &:before {
          position: absolute;
          display: block;
          top: 0;
          bottom: 0;
          left: 0;
          content: '';
          width: 2.5em;
          background: #d1d3d4;
          border-radius: 3px 0 0 3px;
        }
      }
    }

    &:hover:not(:checked) ~ label {
      &:before {
        content: '\2714';
        text-indent: 0.9em;
        color: #c2c2c2;
      }
    }

    &:checked ~ label {
      background: #e0ffdc;

      &:before {
        content: '\2714';
        text-indent: 0.9em;
        color: #333;
        background-color: #ccc;
      }
    }

    &:focus ~ label:before {
      box-shadow: 0 0 0 3px #999;
    }

    &.new-icon {
      &:hover:not(:checked) ~ label {
        &:before {
          content: '\2719';
        }
      }

      &:checked ~ label {
        &:before {
          content: '\2719';
        }
      }
    }
  }

  &-default {
    input[type='radio'],
    input[type='checkbox'] {
      &:checked ~ label:before {
        color: #333;
        background-color: #ccc;
      }
    }
  }

  &-primary {
    input[type='radio'],
    input[type='checkbox'] {
      &:checked ~ label:before {
        color: #fff;
        background-color: #337ab7;
      }
    }
  }

  &-success {
    input[type='radio'],
    input[type='checkbox'] {
      &:checked ~ label:before {
        color: #fff;
        background-color: #5cb85c;
      }
    }
  }

  &-danger {
    input[type='radio'],
    input[type='checkbox'] {
      &:checked ~ label:before {
        color: #fff;
        background-color: #ef5350;
      }
    }
  }

  &-warning {
    input[type='radio'],
    input[type='checkbox'] {
      &:checked ~ label:before {
        color: #fff;
        background-color: #f0ad4e;
      }
    }
  }

  &-info {
    input[type='radio'],
    input[type='checkbox'] {
      &:checked ~ label:before {
        color: #fff;
        background-color: #5bc0de;
      }
    }
  }
}
