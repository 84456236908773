.plooto-content {
  h1.plooto-deposit-header {
    font-size: 26px;
    margin-bottom: 14px;
  }
  .plooto-deposit-pending {
    p {
      line-height: 32px;
      font-weight: 600;
      margin: 15px 0 40px 0;
    }
    img {
      margin: 0 auto;
    }
  }
  .plooto-deposit-description p {
    margin: 34px auto 0 auto;
    line-height: 28px;
    font-size: 16px;
  }
}

.modal-plooto-content {
  border-radius: 10px;
  max-width: 680px;
  .plooto-deposit-pending {
    text-align: center;
    img {
      margin: 16px auto 36px auto;
    }
    h4 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
      &.transaction-on-hold {
        font-size: 27px;
      }
    }
    p {
      font-size: 16px;
      line-height: 25px;
    }
  }

  .modal-header {
    border: 0;
    h3 {
      text-align: center;
      font-weight: 200;
      font-size: 36px;
      padding: 24px 0 0 0;
      margin: 0;
    }
  }
  .modal-footer {
    border-top: 0;
    text-align: center;
    padding-bottom: 60px;
    .btn {
      margin: 0 auto;
      float: none;
      margin-top: 27px;
      margin-bottom: 27px;
      padding: 18px 59px;
      font-size: 18px;
      font-weight: 700;
      background: #2ca6ca;
    }
  }
}
