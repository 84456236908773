input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

input[type='number'] {
  -moz-appearance: textfield;
}

label {
  font-weight: normal;
}

.datepicker.dropdown-menu {
  font-family: inherit !important;
}

select {
  -webkit-appearance: none; /*Removes default chrome and safari style*/
  -moz-appearance: none; /* Removes Default Firefox style*/
  appearance: none;
  padding-right: 30px;
  text-indent: 0.01px;
  margin-right: 30px;
  text-overflow: '';
}

input,
textarea,
select,
a {
  outline: none !important;
  box-shadow: none !important;
}

select::-ms-expand {
  display: none;
}

input,
select {
  outline: 0 !important;
}

table {
  font-size: unset;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block;
}

.tooltip {
  max-width: 450px !important;
  white-space: pre-wrap;
}
.tolltip-inner {
  white-space: pre-wrap;
  max-width: 450px !important;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  color: #999999 !important;
  background: #fff !important;
  border: none !important;
  cursor: default !important;
  font-weight: bold !important;
}
