.payment-filters {
  padding: 0 0 8px 0;
  margin-top: 0px;

  .filter-selections {
    border-top: 1px solid #f4f7fa;
    padding-top: 8px;
    padding-bottom: 5px;
  }
  .filters-area {
    margin-top: 10px;
  }
  .filter-item {
    margin-top: 14px;
    font-size: 14px;
  }

  .btn-filter {
    padding: 10px 20px;
  }
  .dropdown-menu > li > a {
    padding: 6px 20px;
    font-size: 17px;
  }

  .sub-option-part {
    display: inline-block;
    vertical-align: top;
    &.sub-option-part-break {
      margin-left: 55px;
    }
    .flexselect-container {
      display: inline-block;
      &.flexselect {
        width: 300px;
      }

      &.comparison-dropdown {
        width: 160px;
      }
    }
    .form-control {
      font-size: 14px;
      height: 34px;
      margin-bottom: 0;
      &.datePicker {
        width: 100px;
        display: inline-block;
      }
    }
  }

  a.delete {
    line-height: 30px;
    margin: 0 5px;
  }

  .btn-add-filter {
    padding: 5px 10px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
