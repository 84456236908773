.plooto-content,
.bank-details-wrapper {
  #bank-level-security {
    background-image: url(../../img/help/add-bank-icons.png);
    width: 203px;
    height: 54px;
    background-position: 0 -171px;
    margin: 25px auto 0 auto;
  }

  #bank-realtime-tracking {
    background-image: url(../../img/help/add-bank-icons.png);
    width: 73px;
    height: 34px;
    background-position: 0 -226px;
    margin: 25px auto 25px auto;
  }

  #bank-simple-pricing {
    color: #a4b0b5;
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;

    strong {
      font-size: 36px;
      display: block;
      font-weight: 600;
    }
  }

  #cheque-info {
    background-image: url(../../img/help/add-bank-icons.png);
    width: 311px;
    height: 171px;
    margin: 20px auto 0 auto;
    &.us-cheque {
      background-image: url(../../img/help/cheque-info-us.png);
      height: 180px;
    }
  }
  .cheque-info-down {
    &.country-us {
      background-image: url(../../img/help/cheque-info-us-down.png);
    }
    &.country-ca {
      background-image: url(../../img/help/cheque-info-cad-down2.png);
    }
    width: 350px;
    height: 182px;
    margin: 30px 0 5px 30px;
  }
  .check-sample {
    margin-top: 20px;
  }

  .bank-hotline {
    height: 72px;
    display: none;
    line-height: 72px;
    color: #5d6f77;
    font-size: 14px;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;

    img {
      height: 72px;
      float: left;
      padding-right: 20px;

      &.institution001 {
        margin: 0 -30px;
      }

      &.institution002 {
        margin: 0 -10px 0 -17px;
      }

      &.institution003 {
        margin: 0 -47px 0 -49px;
      }

      &.institution004 {
        margin: 0 -15px 0 -14px;
      }

      &.institution006 {
        margin: 0 -26px 0 -31px;
      }

      &.institution010 {
        margin: 0 -48px 0 -42px;
      }

      &.institution016 {
        margin: 0 -21px 0 -27px;
      }

      &.institution614 {
        margin: 0 -27px 0 -25px;
      }
    }

    span {
      display: block;
    }
  }

  label.field-label.select-your-bank {
    margin-top: 30px;
    padding-bottom: 10px;
    font-weight: 700;
  }

  .active-check,
  .signing-authorities label.selectedAuthority {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAPFBMVEUAw5z///8Ox6JS1rwVyKTU9e7Y9vACw53v+/me6Nkzz7Da9vHE8eid6Nlm28Tl+fXO8+xz3slA0rUozKwE2OLkAAAAZUlEQVQoz7XSOxLAIAgEUBaVfDTmd/+7xtHKCKVbWLydsQAI0TH9wi6CpPJQCTnSUphVLzwxj1d5ORAMR+rF13cDQv/Vhaw6nUDWnPYVSM3HprnWFNfy3tMGxwabq7WPAaKdj+ADP00B5m9+DT0AAAAASUVORK5CYII=');
  }

  .bank-selection-wrapper {
    padding-top: 8px;
    padding-bottom: 0px;

    &.institution-required {
      .bank-selection {
        border: 1px solid #fd6e66;
      }
    }

    .bank-selection {
      margin-left: 0;
      margin-right: 0;
      border: 1px solid #fff;
      text-align: center;

      div {
        border-bottom: 1px solid #dae1e9;

        img {
          border-bottom: 4px solid #fff;
          padding: 6px 0;
        }

        &.over {
          cursor: pointer;

          img {
            border-color: #5d6f77;
          }
        }

        &.active {
          img {
            outline: 2px solid #30c48d;
            border-color: #fff;
          }

          z-index: 20;
          position: relative;
        }

        .active-check {
          position: absolute;
          bottom: 5px;
          left: 21px;
          width: 24px;
          height: 24px;
          -webkit-animation: fadein 0.8s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fadein 0.8s; /* Firefox < 16 */
          -ms-animation: fadein 0.8s; /* Internet Explorer */
          -o-animation: fadein 0.8s; /* Opera < 12.1 */
          animation: fadein 0.8s;
        }

        .other-bank {
          text-align: center;
        }
      }

      &.hasActive {
        div {
          z-index: 10;

          img {
            opacity: 0.5;
            border-color: transparent;
          }

          &.active {
            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .signing-authorities {
    margin: 20px 0;

    label {
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      background-repeat: no-repeat;
      background-position: left 3px;
      padding-left: 37px;
      display: block;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAFVBMVEWZmZmYmJidnZ2xsbH////r6+vq6uqYY+/OAAAAWElEQVQY02NwNWIAA0YG5RCGIAYYh0GVwUAAymQQYGZQQMgwMSCAAFQQwUZIQYQxAQ5xQUFBOJtUBYTtEcDnAAUEk4nBAMFhZkiCWg3UrsbgZsQINUg5BQC/swSYUhER8wAAAABJRU5ErkJggg==');
      cursor: pointer;
      margin-bottom: 15px;

      &.disabledAuthority {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAALVBMVEWZmZn///+dnZ3r6+vo6OixsbGfn5/Pz8+np6f29vbg4OC4uLjy8vK+vr6rq6vHSwbvAAAAXUlEQVQY02MQDmKAAlVDBiMGOFBmCEBwWBkUEBwmBhLBMyQ2uwgSp1AKymADSgi6QTmOGxgKRWD2HZRGSDB0Ci4USYBxOCaCJOBSIAm41CWS3IfiORRvowQISlABADj4DEVPTXL7AAAAAElFTkSuQmCC');
        cursor: default;
      }
    }

    span {
      -ms-text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;

      &.person-name {
        font-weight: 700;
      }
    }
  }

  form {
    .signing-authority-divider {
      margin: 20px 0 0 0;
    }
  }

  .remove-signing-authority {
    color: #a4b0b5;
    font-size: 14px;
    display: block;
    float: right;
  }
}

.check-sample-wrapper {
  .col-transit {
    max-width: 180px;
  }
  .col-institution {
    max-width: 111px;
  }
  .col-account {
  }
  .col-routingNumber {
    max-width: 200px;
  }
}
