.component-styled-table {
  h1 {
    font-size: 29px;
    margin-bottom: 30px;
  }
  h2 {
    color: #868686;
    font-size: 20px;
  }
  .alt-header {
    h1 {
      margin-bottom: 15px;
    }
    h2 {
      font-size: 16px;
    }
  }

  .table {
    border: 0;
    margin-bottom: 0;
    margin-top: 40px;
    thead tr th {
      background: #fff;
      border-bottom: 0;
      padding: 0 15px;
    }
    tfoot tr td {
      border: 0;
    }
    tbody tr td {
      border-top: 0;
      border-bottom: 0;
      padding: 3px;
      &.delete {
        padding: 14px 0;
      }
      input[type='text'],
      select {
      }
      .datePicker {
        background: none !important;
        padding-left: 14px;
      }
      .amountPicker {
        background: none !important;
        text-align: right;
      }
    }
  }
  .btn-form {
    height: 55px;
    margin-top: 80px;
    padding: 0;
    font-size: 22px;
    background: #30c48d;
  }
  .green-link {
    color: #30c48d;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  .table tr td.form-sub-option {
    padding-bottom: 20px;

    .icon-sub-long {
      margin-left: 14px;
    }

    .sub-option-part {
      display: inline-block;
      vertical-align: top;
      &.sub-option-part-break {
        margin-left: 55px;
      }
    }
  }
  .contact-filters {
    padding: 13px 0;
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    margin-top: 30px;
  }
  .form-control[disabled] {
    color: #ccc;
  }
}

.integration-container {
  .accounting-other {
    color: #999;
    font-size: 15px;
    a {
      color: #30c48d;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.datepicker {
  padding: 0;
  background-image: url(../../../img/help/datepicker-bg.png);
  background-repeat: repeat-x;

  .datepicker-days,
  .datepicker-months,
  .datepicker-years {
    font-size: 14px;
    table {
      thead {
        tr {
          th {
            font-weight: normal;
            border-radius: 0;
          }
          th.dow {
            border: 1px solid #eaeef5;
          }
          th.prev,
          th.datepicker-switch,
          th.next {
            height: 39px;
          }
        }
      }
      tbody {
        tr td {
          border: 1px solid #eaeef5;
          &.active,
          &.active:hover,
          span.active,
          span.active:hover {
            border: 1px solid #eaeef5;
            background: #30c48d !important;
            color: #fff !important;
            border-radius: 0;
            text-shadow: none;
          }
          .month,
          .year {
            border: 1px solid #eaeef5;
          }
          &.today.day {
            cursor: pointer !important;
          }
          &.disabled,
          &.today.day.disabled {
            color: #ccc;
            cursor: default !important;
          }
        }
      }
    }
  }
}
.icon_repeat,
table.table .btn-sm.icon.icon_repeat {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjQ5OSAzLjczMmwtMi4yOTctMi4zN2MtLjQzMS0uMjE1LS43OS0uMDcxLS43OS41MDNWMy4yM0g1LjA4OUMuNjM4IDMuMjMuNzA5IDYuMzE2LjcwOSA2LjYwNHYzLjM3NFMyLjY0OSA4LjExIDIuNjQ5IDguMDM5VjYuNjA0YzAgLjA3MS4wNzItMS41MDggMi40NC0xLjUwOGg3LjMyNFY2LjQ2YzAgLjUwMi4zNTkuNzE4Ljc5LjUwMmwyLjI5Ny0yLjM2OWMuMzU5LS4zNTkuMzU5LS42NDYgMC0xLjAwNXYuMTQ0em0tLjIxNSAyLjk0M3MtMS45MzkgMS44NjctMS45MzkgMS45Mzl2MS40MzZjMC0uMDcyLS4wNzIgMS41MDctMi40NCAxLjUwN0gzLjU4di0xLjM2NGMwLS41MDItLjM1OS0uNzE4LS43OS0uNTAyTC40OTUgMTIuMDZjLS4zNTkuMzU5LS4zNTkuNTc0IDAgMS4wMDVsMi4yOTggMi4zNjljLjQzLjIxNS43OS4wNzIuNzktLjUwM3YtMS4zNjRoNy4zMjJjNC40NTEgMCA0LjM4LTMuMDg3IDQuMzgtMy4zNzRWNi42NzV6IiBmaWxsPSIjMmViYTk3IiBzdHJva2U9Im51bGwiLz48L3N2Zz4=');
  margin: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: inline-block;
  opacity: 1;
  vertical-align: middle;

  &.disabled {
    display: none;
  }

  &.is-recurring {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjUyMiAzLjY3M0wxMy4yMSAxLjI4OGMtLjQzNC0uMjE3LS43OTUtLjA3Mi0uNzk1LjUwNnYxLjM3M2gtNy4zN0MuNTY1IDMuMTY3LjYzOCA2LjI3NC42MzggNi41NjN2My4zOTZzMS45NS0xLjg3OSAxLjk1LTEuOTUxVjYuNTYzYzAgLjA3Mi4wNzMtMS41MTggMi40NTctMS41MThoNy4zN3YxLjM3M2MwIC41MDYuMzYxLjcyMy43OTUuNTA2bDIuMzEyLTIuMzg0Yy4zNjEtLjM2Mi4zNjEtLjY1IDAtMS4wMTJ2LjE0NXptLS4yMTcgMi45NjJzLTEuOTUgMS44NzktMS45NSAxLjk1djEuNDQ2YzAtLjA3Mi0uMDczIDEuNTE3LTIuNDU3IDEuNTE3aC03LjM3di0xLjM3MmMwLS41MDYtLjM2MS0uNzIzLS43OTUtLjUwNkwuNDIxIDEyLjA1NGMtLjM2MS4zNjEtLjM2MS41NzggMCAxLjAxMmwyLjMxMiAyLjM4NGMuNDM0LjIxNy43OTUuMDcyLjc5NS0uNTA2di0xLjM3Mmg3LjM3YzQuNDggMCA0LjQwNy0zLjEwNyA0LjQwNy0zLjM5NlY2LjYzNXoiIGZpbGw9IiNjY2MiIHN0cm9rZT0ibnVsbCIvPjwvc3ZnPg==');
  }

  &.stopped {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI2MwMCIgZD0iTTE1LjQgMy43MTZsLTIuMjgyLTIuMzU0Yy0uNDI4LS4yMTQtLjc4NS0uMDcxLS43ODUuNXYxLjM1NUg1LjA1OEMuNjM1IDMuMjE3LjcwNiA2LjI4NC43MDYgNi41Njl2My4zNTNzMS45MjYtMS44NTUgMS45MjYtMS45MjZWNi41NjljMCAuMDcxLjA3Mi0xLjQ5OCAyLjQyNi0xLjQ5OGg3LjI3NXYxLjM1NWMwIC41LjM1Ny43MTQuNzg1LjVMMTUuNCA0LjU3MmMuMzU2LS4zNTcuMzU2LS42NDIgMC0uOTk5di4xNDN6bS0uMjEzIDIuOTI0UzEzLjI2IDguNDk1IDEzLjI2IDguNTY2djEuNDI3YzAtLjA3MS0uMDcyIDEuNDk4LTIuNDI2IDEuNDk4SDMuNTZ2LTEuMzU1YzAtLjUtLjM1Ny0uNzE0LS43ODUtLjVMLjQ5MiAxMS45OWMtLjM1Ni4zNTctLjM1Ni41NyAwIC45OTlsMi4yODMgMi4zNTRjLjQyOC4yMTQuNzg1LjA3MS43ODUtLjV2LTEuMzU1aDcuMjc1YzQuNDIzIDAgNC4zNTItMy4wNjcgNC4zNTItMy4zNTJWNi42NHoiIHN0cm9rZT0ibnVsbCIvPjwvc3ZnPg==');
  }
}

.icon_fast,
table.table .btn-sm.icon.icon_fast {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEQAACxEBf2RfkQAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xNkRpr/UAAAJSSURBVEhLnZQHkuIwEEXn/mcjDkXOwTZhyBkMvXoNMrLNsLX7q1TYSH7q8KWvy+US/ss4nU463s2540s+6H6/y/l8lv1uJ9vtVp9ns5n5PT1X/K5fwfv9Xnzfl16vJ8PBQEbDoYFOpV6vy2KxkCAI5HA4PFenlQLfbjf9qNFoKHiz2cjxeFRIq9WSZrOpm3qjkb4vl0vNLKkYGOjQRMbHO5N+GIbPGdESFItFjR5QeL0qFPhqtUrBY+DJZCJNE6lpzvOfh3gng0wmI9PpNILwO5/Ppd1ua1auIvDJTAAldfPJ408jPp6Mx5LL5RS81fmXyLLf7+sanq0i8NRES4OuJkVX1LZarSoUOM5IimAoiTunYGo5MLvSbbdWdxPByDQJKKNcLovx6HP2JSKlL+v1OvpewdQQMF51tdmspVAoROBSqaRuCIxbcI7bC5pOOWJgCj8w3cZGVmRRqz1K8G5UKpVY9EHgi+d5UZ1jYCxmRfTdblcHhyKfz0fQbDarabsCShaxiGkYnXUXszNRM35+8PCrJKx1e4E6nbZaLwbmhR2TlrHC39Zupe/vVAOpNVm5XlYw4vR0Op3U4bCb2hK4UVmxMVm4Vo3AREqdPW8UWxCGV72IALOxe8wRfeHkPQ7WSxEYES3Nwjr25sL0eBQPu65BNJhNufWSJYyBEXBS5ySNx4GWqF6r6R1hG0qU3HxEyuWUzAKlwIiFpOb7noyNhSgBWQCiSa1WU0vGBu+ajd6CrR7RbdVuWJFrEhjleRelq49ghLWA4ISkGz7pr+D/k8gfRM5LWo+FTa4AAAAASUVORK5CYII=');
  background-repeat: no-repeat;
  background-position: bottom;
  margin: 0;
  width: 22px;
  height: 22px;
  opacity: 1;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  &.disable {
    display: none;
  }
  &.is-fast {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEQAACxEBf2RfkQAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xNkRpr/UAAAN/SURBVEhLnVVJTFNRFAViTNS4NHHpwjUbEzcujGjiHIeFyIJEExdq0OAQNUbRxMSAJLJAFyooiRqHiKJBJhEQaAGZh1IKtLZAoaUUOtG5Pd77+n/5hUKip3n57fv3nXvfvefepgSDwfC/LLffF/bQSvZOuVKwBsLRKEx+Nzrds2h2zGCCvpfOjMBIz6hksxqSEvOhLrcNDyZ6cX6sFbn6NtwwdOD5jBZ7Byrx0aZHwWQ/NIsLsQNJsILYGwnj0VQ/DgxVE3EPGhbMGCaCfo8dJ4d/4KimFr3uOdwxdonfFXNGhKIR6fQSEoj9RHrV0I7DQzVoc1nhDAekN0AJpWBr+xtcMbQhEIlgPuTHV7sRxzR1qLSbRNqUSCB+Mq3BQSId8zkRUWRxzOvEvsEqpLaU4BmlIySRBCnSt7PjyNT+hHZZWuLEo14H9tPhRoc5wTt/L5oaxCZVGVJaXqDJMZ3g1BMO4eJ4Kx6TzWIkJO1KxGz2lKK9SQWyBX28FcegZx47+yoE6QbVS6GI5WggZycoJcp3gthBubw0rkK5zZAQrY9yfuvPb6QRKROnd3+CJeAVgSxZxQp+hIpaMz8ZPy+I9T4XEauhdlrEpgxWxJb214KU1/bOD6SGTjwkGRZM9tE5p2QJIUdOGQuAIYhZj7l6NbpJuzIcoQB29X+Lk8orVVo7ej/DHFiUrIH8iT7cM3aLWzIEsY4Kd5ki7nDNik2G2mXBmdFfyNY1IWPwOzarY8Xjtb61FHULU5JlDHmk63y6RULE1qAXF6iytQpj9rxAUfMqteiEhmXiHL1KSE1GlD5ZJLl3JD25WQQxJ/wueSwyJ0pGRjEpZqPqlSDd1vkeFgpECc51BrU6d6gMQcyonp9A1kiDaA4lODK+JpOuay0RUSmVw9Gy4xxSlVKqcWKWDL+8TVWfVRjYqXXPjTUjjUizdY0iNTIi5KCdWj9TWy8UxL9lxIkZRr8LZ6lg12leDNDQYTMW/SFq8/SecjHx5K7jJ49Sdspt7g4Hxb6MBGKGgTSdZ4pNrkKaclWUot2Uv2KzRuSfCXhA8eQ7TQUrtYzQLfzS6SWsIGbwdXkm3Dd1o5DmLqfgGk21U3TlPeTkOLUvF5sdJCs2Iykxgy/MDlTUjWVWHeoph19o9vK/Cf+rcAMtZXQlViVmcB6tNBs8dH1WAo/LtciUWJP4/wH8BUZD5VhWPk+hAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: bottom;
  }
}

.icon_fast_solo {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEQAACxEBf2RfkQAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xNkRpr/UAAAN/SURBVEhLnVVJTFNRFAViTNS4NHHpwjUbEzcujGjiHIeFyIJEExdq0OAQNUbRxMSAJLJAFyooiRqHiKJBJhEQaAGZh1IKtLZAoaUUOtG5Pd77+n/5hUKip3n57fv3nXvfvefepgSDwfC/LLffF/bQSvZOuVKwBsLRKEx+Nzrds2h2zGCCvpfOjMBIz6hksxqSEvOhLrcNDyZ6cX6sFbn6NtwwdOD5jBZ7Byrx0aZHwWQ/NIsLsQNJsILYGwnj0VQ/DgxVE3EPGhbMGCaCfo8dJ4d/4KimFr3uOdwxdonfFXNGhKIR6fQSEoj9RHrV0I7DQzVoc1nhDAekN0AJpWBr+xtcMbQhEIlgPuTHV7sRxzR1qLSbRNqUSCB+Mq3BQSId8zkRUWRxzOvEvsEqpLaU4BmlIySRBCnSt7PjyNT+hHZZWuLEo14H9tPhRoc5wTt/L5oaxCZVGVJaXqDJMZ3g1BMO4eJ4Kx6TzWIkJO1KxGz2lKK9SQWyBX28FcegZx47+yoE6QbVS6GI5WggZycoJcp3gthBubw0rkK5zZAQrY9yfuvPb6QRKROnd3+CJeAVgSxZxQp+hIpaMz8ZPy+I9T4XEauhdlrEpgxWxJb214KU1/bOD6SGTjwkGRZM9tE5p2QJIUdOGQuAIYhZj7l6NbpJuzIcoQB29X+Lk8orVVo7ej/DHFiUrIH8iT7cM3aLWzIEsY4Kd5ki7nDNik2G2mXBmdFfyNY1IWPwOzarY8Xjtb61FHULU5JlDHmk63y6RULE1qAXF6iytQpj9rxAUfMqteiEhmXiHL1KSE1GlD5ZJLl3JD25WQQxJ/wueSwyJ0pGRjEpZqPqlSDd1vkeFgpECc51BrU6d6gMQcyonp9A1kiDaA4lODK+JpOuay0RUSmVw9Gy4xxSlVKqcWKWDL+8TVWfVRjYqXXPjTUjjUizdY0iNTIi5KCdWj9TWy8UxL9lxIkZRr8LZ6lg12leDNDQYTMW/SFq8/SecjHx5K7jJ49Sdspt7g4Hxb6MBGKGgTSdZ4pNrkKaclWUot2Uv2KzRuSfCXhA8eQ7TQUrtYzQLfzS6SWsIGbwdXkm3Dd1o5DmLqfgGk21U3TlPeTkOLUvF5sdJCs2Iykxgy/MDlTUjWVWHeoph19o9vK/Cf+rcAMtZXQlViVmcB6tNBs8dH1WAo/LtciUWJP4/wH8BUZD5VhWPk+hAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-position: bottom 10px;
  margin: 6px 2px 0 3px;
  width: 22px;
  height: 22px;
  opacity: 1;
  cursor: pointer;
  display: inline-block;
}
