@keyframes animationFrames1Scale {
  0% {
    transform: scale(0.05);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes animationFrames1Opacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
.navbar-inverse .nav-tabs a .point-of-interest svg {
  display: block;
  width: 88px;
  height: 88px;
  margin: 0;
}
.point-of-interest {
  background: url(../../../img/help/attention.png);
  position: absolute;
  width: 88px;
  height: 88px;
  top: 23px;
  left: 251px;
  z-index: 2000;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;

  @media (max-width: 1000px) {
    left: 247px;
    &.point-of-interest-bank {
      left: 316px;
    }
  }
  @media (min-width: 1001px) {
    left: 361px;
    &.point-of-interest-bank {
      left: 475px;
    }
  }
  @media (min-width: 1200px) {
    left: 481px;
    &.point-of-interest-bank {
      left: 671px;
    }
  }

  .puff_animation {
    transform: scale(2);
    position: absolute;
    top: -44px;
    left: -44px;
  }
  .puff_animation .layer1 {
    position: absolute;
    left: 0;
    top: 0;
    animation: animationFrames1Scale cubic-bezier(0.165, 0.84, 0.44, 1) 1.8s infinite,
      animationFrames1Opacity cubic-bezier(0.3, 0.61, 0.355, 1) 1.8s infinite;
    transform-origin: 44px 44px;
  }
  .puff_animation .layer2 {
    position: absolute;
    left: 0;
    top: 0;
    animation: animationFrames1Scale cubic-bezier(0.165, 0.84, 0.44, 1) 1.8s infinite,
      animationFrames1Opacity cubic-bezier(0.3, 0.61, 0.355, 1) 1.8s infinite;
    animation-delay: -0.9s;
    transform-origin: 44px 44px;
  }
}

.tooltip {
  &.point-of-interest-tooltip {
    margin-top: -15px;
  }

  .tooltip-indicator {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAOCAMAAAAPOFwLAAAAZlBMVEUAAADL2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/L2d/5+frM2uDn7O/t8fPc5enY4ubU3+Tv8vTp7vHl6+7f5+vQ3OLM2d/h6ezN2+A/6j9nAAAAE3RSTlMAnJSlN49YUEQ9MYlwa2AoJRMLzsvtrgAAAHpJREFUGNN1zdkSgjAQRFFC2MTdnpCEVf//J8WyFJqE+zZ1pqoTLjsd6GZUfnB676EQA6CTMqqlNPjUvIqIXqdZvy51yGmPX0YFerRYGvKNXjzW+TPpfWqJW7nR8BNcn640d9hm/14rC4Sus9kelRoNYplRV4k4Qqpzb/zHEIF1qtNDAAAAAElFTkSuQmCC');
    width: 30px;
    height: 14px;
    position: absolute;
    top: -8px;
    left: 50%;
    margin-left: -15px;
  }
  .tooltip-plooto {
    background: #f9f9fa;
    border: 1px solid #cddbe0;
    padding: 30px;
    min-width: 300px;
    text-align: left;

    border-radius: 10px;
    -webkit-box-shadow: 5px 3px 51px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 5px 3px 51px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 5px 3px 51px 0px rgba(0, 0, 0, 0.15);

    h4 {
      margin-top: 0;
      color: #4d4d4d;
      font-size: 18px;
    }
    p {
      color: #5d6f77;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
