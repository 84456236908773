.payment-approvals-satisfied {
  border: 2px dashed #ff9c00;
  padding: 10px;
  text-align: center;
  background: #ffebcb;
  margin: 20px 0;
  border-radius: 10px;
  font-size: 16px;
  .approve-button-wrapper {
    margin: 10px 0;
  }
}
