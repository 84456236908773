.link-button {
  background: none;
  border: none;
  color: $color-plooto-blue;
  cursor: pointer;
  padding: 0;

  &:hover,
  &:focus {
    color: $color-plooto-blue;
    text-decoration: underline;
  }
}
