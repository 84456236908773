&.plooto-content-wrapper {
  .plooto-content.verification {
    padding-top: 130px;
  }
}
#verification-bar-wrapper {
  display: none;
  height: 142px;
  &.collapsed {
    height: 86px;
  }
}
.tutorial-visible #verification-container,
.tutorial-visible #reminder-bar {
  display: none;
}
.tutorial-visible #navigation-top,
.tutorial-visible #navigation-company {
  display: none;
}
#verification-bar {
  position: absolute;
  width: 100%;
  top: 141px;
  background: #4b749c;
  border-bottom: 0;
  height: 126px;
  padding-top: 10px;
  z-index: 999;
  display: none;
  border-radius: 0;

  &.collapsed {
    height: 70px;
  }

  h1 {
    color: #fff;
    font-size: 17px;
    text-align: center;
    padding-left: 5px;
    margin-top: 18px;
    margin-bottom: 16px;
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .nav-pills {
    background: none;
    border: 0;
    margin-bottom: 0;
    li {
      position: relative;
      text-align: center;
      a {
        color: #fff;
        background: none;
        text-decoration: none;

        &:hover {
          color: #ccc;
          .fa.bar-icon {
            opacity: 0.8;
          }
        }
      }
    }
    .fa {
      vertical-align: middle;
      padding-right: 12px;
      font-size: 30px;
      color: #aaa;
      &.fa-caret-down {
        padding-top: 12px;
        color: #4b749c;
        position: absolute;
        left: 19px;
        bottom: -32px;
        margin-left: -14px;
      }
      &.completed {
        color: #6bff6b;
      }
      &.required {
        color: #ffd215;
      }
    }
    h4 {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 0;
    }
  }
  .navbar-padding {
    padding: 0 30px;
  }
  .collapse-navigation {
    a {
      height: 18px;
      line-height: 18px;
      color: #fff;
      font-size: 33px;
      i {
        line-height: 18px;
      }
    }
  }
  .current-navigation-address {
    .col-sm-20percent {
      height: 5px;
      &.current {
        background: #ffd37b;
      }
    }
  }
}
