@import 'variables.less';

label.field-label.required::after {
  content: '*';
  color: #30c48d;
  font-size: 13px;
  vertical-align: baseline;
  position: relative;
  top: -0.1em;
  left: 2px;
}
.plooto-content {
  padding-top: 190px;
  margin: 0 auto 0 auto;
  padding-bottom: 200px;

  &.plooto-content-front {
    padding-top: 64px;
  }

  form {
    .btn.btn-form {
      margin-top: 40px;
      span {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 18px;
      }
    }
    a.btn.btn-form {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .divider {
      height: 1px;
      width: 100%;
      display: block;
      margin: 24px 0;
      overflow: hidden;
      background-color: #dae1e9;
      &.short-divider {
        margin: 30px 0;
      }
      &.tiny-divider {
        margin: 5px 0;
      }
    }
    .fields-are-mandatory {
      font-size: 14px;
      color: #5d6f77;
      font-weight: normal;
      strong {
        color: #30c48d;
        font-size: 30px;
        vertical-align: middle;
        font-weight: normal;
      }
    }
    .form-logic-group {
      margin-top: 28px;
    }
    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control {
      color: #bbb;
    }
  }

  .content-focus {
    background: #fff;
    border: 2px solid #cbd9df;
    padding: 30px 0;
    position: relative;

    button.btn-form {
      padding: 15px 0;
    }
  }
  h1 {
    margin: 24px 0;
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: #4d4d4d;
  }
  h3 {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 700;
    color: #4d4d4d;
  }
  .field-label {
    &.error-label {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAMAAADjcdz2AAAAUVBMVEX9bmb/////8fD9e3T9dGz/9PT+n5n+mZP9hX7/4N7+vbr/5eT+2Nb+urb+tbH+pqH+lpD9iIH9d2//+vr/+fn/6+n+09H+rKf9jYb9fXb9cWlDkoHaAAAAeElEQVQY01WP2Q7DIAwEx5QrNHfv/v+HtjhWEuZlVyMjscgfN6QlzyXUXsXgUdayicjOVMWo9dppFOHx1Sai4R0vzoKR3Io3vhUXaMXKx8QNJRPt1G4nes7/oEOSFrlrREFCPp4soW5xM0YK21p5Jg8+9jZfcc7KD6AYAqnGzyT0AAAAAElFTkSuQmCC');
      background-repeat: no-repeat;
      background-position: right center;
      padding-right: 20px;

      &.hide-error-indicator {
        background: none;
        padding-right: 0;
      }
    }
  }
  .contains-form-error-bubble {
    .error-label {
      color: #fd6e66;
    }
  }
  .content-help {
    text-align: center;
    padding-top: 7px;
    h3 {
      font-size: 20px;
      font-weight: 600;
      color: #5d6f77;
      margin-bottom: 16px;
      margin-top: 98px;

      @media (max-width: (@screen-max-width-md)) {
        margin-top: 20px;
      }
    }
    .three-points {
      h3 {
        margin-top: 50px;
      }
      .alt-points {
        .help-point {
          margin: 25px 0 0 0;
        }
        h3 {
          font-size: 14px;
          margin: 10px 0;
        }
      }
    }
    .testimony {
      padding: 0 0 0 60px;
      margin: 0 auto 50px auto;

      position: relative;
      padding-bottom: 50px;
      background-image: url(../../img/help/testimony-1.jpg);
      background-repeat: no-repeat;
      background-position: bottom right;
      font-size: 14px;
      margin-right: 35px;

      h3 {
        padding-right: 100px;
        margin-top: 25px;
      }
      p {
        display: block;
        padding: 22px 19px;
        background: #fff;
        color: #5d6f77;
        text-align: left;
        margin-right: 100px;

        -webkit-border-radius: 25px;
        -webkit-border-bottom-right-radius: 0;
        -moz-border-radius: 25px;
        -moz-border-radius-bottomright: 0;
        border-radius: 25px;
        border-bottom-right-radius: 0;
      }
      strong {
        font-weight: 600;
        position: absolute;
        right: 135px;
        bottom: 30px;
        text-align: right;
        font-style: italic;
        color: #5d6f77;
      }
      em {
        position: absolute;
        right: 135px;
        bottom: 10px;
        text-align: right;
        font-style: normal;
        color: #30c48d;
      }
    }
    p {
      font-size: 14px;
      color: #a4b0b5;
    }
  }
}
.errors-container {
  display: none;
  border: 3px solid #fd6e66;
  border-radius: 5px;
  font-weight: 600;
  .errors-container-header {
    color: #fff;
    font-size: 14px;
    padding: 5px 0 5px 30px;
    background-color: #fd6e66;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAV1BMVEX99/f9bmb98vL96+r94+H9eHD9cGj90c79zsv9jIX9e3T93939y8j9yMX9w8D9tbH9sKz9ran9hoD9c2v97+797u792tj9vLn9rKf9l5L9lI79k439gXp5jcWVAAAAc0lEQVQY01WPVw4DIQwFB4e2bE9v9z9nMCIJzJc19pP8MJm0hdGG3emchd+EwuVehJ/4sahYUY4Dym54C0peKZK40QpWbC+uSC8O0IvzV/kTBctcT+ti4UX7BwMmtpHZYJz9R0an5VKgEp12yTyigExPnT+TDgLTHfdfngAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: 6px center;
  }
  .errors-container-body {
    color: #4d4d4d;
    margin: 25px 35px;
    font-size: 14px;
    div {
      margin-bottom: 13px;
    }
  }
}

table {
  tr td.no-results {
    padding: 25px 0;
  }
}
.btn-add-lg {
  padding-left: 30px;
  padding-right: 20px;
}
.entry-type-credit {
  color: #50b079;
  font-weight: 600;
}
.entry-type-debit {
  color: #b14e4b;
  font-weight: 600;
}

.btn.img-button {
  display: inline-block;
  overflow: hidden;
  text-indent: -9999px;
  box-shadow: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  border: 0;

  &:hover {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.06);
  }
  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &.pay-single-contact {
    background: url(../../img/help/button-pay-single.png);
    width: 240px;
    height: 70px;
  }
  &.import-contacts-software {
    background: url(../../img/help/button-import-contacts-software.png);
    width: 286px;
    height: 69px;
  }
  &.import-xero {
    background: url(../../img/help/button-import-xero.png);
    width: 286px;
    height: 69px;
  }
  &.import-quickbooks_desktop {
    background: url(../../img/help/button-import-quickbooks_desktop.png);
    width: 286px;
    height: 69px;
  }
  &.import-quickbooks_online {
    background: url(../../img/help/button-import-quickbooks_online.png);
    width: 286px;
    height: 69px;
  }
  &.import-bills-quickbooks_desktop {
    background: url(../../img/help/button-import-bills-quickbooks_desktop.png);
    width: 286px;
    height: 69px;
  }
  &.import-bills-quickbooks_online {
    background: url(../../img/help/button-import-bills-quickbooks_online.png);
    width: 286px;
    height: 69px;
  }
  &.import-bills-xero {
    background: url(../../img/help/button-import-bills-xero.png);
    width: 286px;
    height: 69px;
  }
  &.add-a-contact {
    background: url(../../img/help/button-add-a-contact.png);
    background-repeat: no-repeat;
    width: 285px;
    height: 69px;
  }
  &.mass-import-contacts {
    background: url(../../img/help/button-mass-import-contacts.png);
    background-repeat: no-repeat;
    width: 240px;
    height: 69px;
  }
  &.add-contacts {
    background: url(../../img/help/button-add-contacts.png);
    background-repeat: no-repeat;
    width: 285px;
    height: 69px;
  }

  &.pay-cra-tax {
    background: url(../../img/help/button-cra-tax.png);
    background-repeat: no-repeat;
    width: 285px;
    height: 70px;
  }
  &.send-payments {
    background: url(../../img/help/button-send-payments.png);
    background-repeat: no-repeat;
    width: 285px;
    height: 69px;
  }
  &.request-single-contact {
    background: url(../../img/help/button-request-single.png);
    width: 240px;
    height: 70px;
  }
  &.import-from-accounting-software {
    background: url(../../img/help/button-import-accounting-software.png);
    width: 285px;
    height: 69px;
  }
  &.import-invoices-from-accounting-software {
    background: url(../../img/help/button-import-invoices-accounting-software.png);
    width: 285px;
    height: 69px;
  }
  &.quickbooks-online {
    background: url(../../img/help/button-qbo.png);
    width: 224px;
    height: 64px;
  }
  &.quickbooks-desktop {
    background: url(../../img/help/button-qbd.png);
    width: 224px;
    height: 64px;
  }
  &.xero {
    background: url(../../img/help/button-xero.png);
    width: 254px;
    height: 64px;
    border-radius: 35px;
  }
  &.btn-add-more {
    background: url(../../img/help/add-more.png);
    width: 174px;
    height: 45px;
    border-radius: 35px;
  }
}
.datepicker-dropdown {
  z-index: 1030 !important;
}

.col-xs-20percent,
.col-sm-20percent,
.col-md-20percent,
.col-lg-20percent {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-20percent {
  width: 20%;
  float: left;
}
@media (min-width: 600px) {
  .col-sm-20percent {
    width: 20%;
    float: left;
  }
}
@media (min-width: 900px) {
  .col-md-20percent {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-20percent {
    width: 20%;
    float: left;
  }
}
.content-focus .close-icon {
  i.fa {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 30px;
    color: #ccc;
  }
}
.card-plooto {
  .plooto-old-modal-contents {
    padding: 48px 200px;
  }
  .divider {
    height: 1px;
    width: 100%;
    display: block;
    margin: 50px 0;
    overflow: hidden;
    background-color: #dae1e9;
    &.short-divider {
      margin: 30px 0;
    }
    &.tiny-divider {
      margin: 5px 0;
    }
  }
}

.select-container {
  position: relative;

  .iconContainer {
    position: absolute;
    right: -25px;
    top: calc(50% - 10px);
  }

  .loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
  }

  .loader::after,
  .loader::before {
    content: '';
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #173bd9 #173bd9 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  .loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
