@import 'variables.less';

.plooto-content {
  .show-contact-divider {
    margin-bottom: 0;
  }
  .show-contact-information {
    padding-top: 15px;
    font-size: 14px;
  }
}
.double-check {
  border: 1px solid #30c48d;
  -webkit-border-radius: 15px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius: 15px;
  -moz-border-radius-topleft: 0;
  border-radius: 15px;
  border-top-left-radius: 0;

  padding: 15px 20px;
  color: #a4b0b5;
  font-size: 14px;
  margin-top: 20px;
  strong {
    color: #6c6c6c;
  }
}
