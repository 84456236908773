.table.newPaymentTable {
  border: 0;
  margin-bottom: 0;
  tr td {
    vertical-align: top !important;
  }
}

.text-line-break {
  white-space: pre-wrap;
}
