.table-details {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  th {
    font-weight: 600;
  }
}

.details-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  font-size: 24px;
  margin-bottom: 15px;
  line-height: 40px;
  color: #606e75;

  &.details-header-first {
    margin-top: 0;
  }
  &.details-table {
    border-bottom: 0;
    margin-bottom: 0px;
  }
}
.details-container {
  margin-bottom: 30px;

  .col-header {
    font-weight: 600;
  }
  .row {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.details-buttons {
  float: right;
  .btn {
    padding: 8px 20px;
    margin-left: 5px;
  }
}

.payment-approval-status-Approval {
  color: #e19830;
}
.payment-approval-status-Rejected {
  color: #c00;
  font-weight: bold;
}
.payment-approval-status-Approved,
.payment-approval-status-Initiated {
  color: #30c48d;
  font-weight: bold;
}
.approval-history-table td {
  .btn.btn-form {
    margin-top: 0;
    position: relative;
  }
}
.ledger-date-filter {
  font-size: 15px;
}

.ledger-date-filter input {
  width: 100px;
  display: inline-block;
  margin-bottom: 5px;
  font-size: 15px;
  height: 34px;
}
.btn-get-file {
  padding: 5px 10px;
  margin-bottom: 3px;
  margin-left: 2px;
}
