.verification .tiny-divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.verification .col-sm-3 h3 {
  margin-top: 20px !important;
}

.verification .statusArea {
  position: relative;
}

.verification .statusInReview {
  position: absolute;
  right: 0;
  top: 15px;
  color: #bcbcbc;
  height: 40px;
  padding-top: 7px;
  padding-right: 50px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCI+PHBhdGggZD0ibTIwIDBjLTExIDAtMjAgOC45LTIwIDIwczguOSAyMCAyMCAyMCAyMC04LjkgMjAtMjAgLTguOS0yMC0yMC0yMHptMCAzOC43Yy0xMC4zIDAtMTguNy04LjQtMTguNy0xOC43IDAtMTAuMyA4LjQtMTguNyAxOC43LTE4LjdzMTguNyA4LjQgMTguNyAxOC43YzAgMTAuMy04LjQgMTguNy0xOC43IDE4Ljd6IiBmaWxsPSIjZTE5ODMwIi8+PHJlY3QgaGVpZ2h0PSIzLjQiIHdpZHRoPSIzLjQiIHk9IjI2LjciIHg9IjExLjMiIGZpbGw9IiNlMTk4MzAiLz48cmVjdCBoZWlnaHQ9IjMuNCIgd2lkdGg9IjMuNCIgeT0iMjYuNyIgeD0iMTguMyIgZmlsbD0iI2UxOTgzMCIvPjxyZWN0IGhlaWdodD0iMy40IiB3aWR0aD0iMy40IiB5PSIyNi43IiB4PSIyNS4yIiBmaWxsPSIjZTE5ODMwIi8+PC9zdmc+') !important;
  background-position: right center;
  background-repeat: no-repeat;
}

.verification .statusPendingOther {
  position: absolute;
  right: 0;
  top: 15px;
  color: #bcbcbc;
  height: 40px;
  padding-top: 7px;
  padding-right: 50px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCI+PHN0eWxlPi5he3N0cm9rZTpudWxsO30uYntmaWxsOiNmZjdmMDA7c3Ryb2tlOm51bGw7fTwvc3R5bGU+PGcgY2xhc3M9ImEiPjxnIGNsYXNzPSJhIj48cGF0aCBkPSJtMjAgMjcuNWMtMS4xIDAtMiAwLjktMiAyczAuOSAyIDIgMmMxLjEgMCAyLTAuOSAyLTIgMC0xLjItMC45LTIuMS0yLTIuMXoiIGNsYXNzPSJiIi8+PHBhdGggZD0ibTM4LjggMzQuN2MxLjMtMi4yIDEuMy00LjggMC03bC0xMi43LTIyLjFjLTEuMy0yLjItMy41LTMuNS02LjEtMy41cy00LjggMS4zLTYuMSAzLjVsLTEyLjggMjIuMWMtMS4zIDIuMi0xLjMgNC45IDAgNy4xIDEuMyAyLjIgMy41IDMuNSA2LjEgMy41bDI1LjUgMGMyLjUgMCA0LjgtMS4zIDYuMS0zLjV6bS0yLjgtMS42Yy0wLjcgMS4yLTIgMS45LTMuNCAxLjlsLTI1LjUgMGMtMS40IDAtMi42LTAuNy0zLjMtMS45IC0wLjctMS4yLTAuNy0yLjcgMC0zLjlsMTIuOC0yMi4xYzAuNy0xLjIgMS45LTEuOSAzLjMtMS45IDEuNCAwIDIuNiAwLjcgMy4zIDEuOWwxMi44IDIyLjFjMC43IDEuMiAwLjcgMi42IDAgMy44eiIgY2xhc3M9ImIiLz48cGF0aCBkPSJtMTkuNSAxMy4yYy0xIDAuMy0xLjYgMS4yLTEuNiAyLjIgMCAwLjYgMC4xIDEuMyAwLjEgMS45IDAuMSAyLjQgMC4zIDQuOCAwLjQgNy4zIDAgMC44IDAuNyAxLjQgMS41IDEuNCAwLjggMCAxLjUtMC42IDEuNS0xLjUgMC0wLjUgMC0xIDAtMS41IDAuMS0xLjYgMC4yLTMuMSAwLjMtNC43IDAtMSAwLjEtMiAwLjItMy4xIDAtMC40IDAtMC43LTAuMi0xIC0wLjQtMC45LTEuNC0xLjQtMi40LTEuMXoiIGNsYXNzPSJiIi8+PC9nPjwvZz48L3N2Zz4NCg==') !important;
  background-position: right center;
  background-repeat: no-repeat;
}
.verification .statusCompleted {
  position: absolute;
  right: 0;
  top: 15px;
  color: #bcbcbc;
  height: 40px;
  padding-top: 7px;
  padding-right: 50px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCI+PHBhdGggZD0ibTIwIDBjLTExIDAtMjAgOC45LTIwIDIwczguOSAyMCAyMCAyMCAyMC04LjkgMjAtMjAgLTguOS0yMC0yMC0yMHptMCAzOC43Yy0xMC4zIDAtMTguNy04LjQtMTguNy0xOC43IDAtMTAuMyA4LjQtMTguNyAxOC43LTE4LjdzMTguNyA4LjQgMTguNyAxOC43YzAgMTAuMy04LjQgMTguNy0xOC43IDE4Ljd6IiBmaWxsPSIjMDBjMzljIi8+PHBvbHlnb24gcG9pbnRzPSIyOC4yIDE1LjEgMjcuNCAxNC4zIDE3LjUgMjQuMSAxMi43IDE5LjIgMTEuOCAyMC4xIDE3LjQgMjUuNyAxNy41IDI1LjYgMTcuNiAyNS43ICIgZmlsbD0iIzAwYzM5YyIvPjwvc3ZnPg==');
  background-position: right center;
  background-repeat: no-repeat;
}
